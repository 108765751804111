import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from '../../../shared/service/base-api.service';
import { Observable } from 'rxjs';
import { Signature } from '../model/signature';

@Injectable()
export class SignatureApiService extends BaseApiService<Signature>  {

  protected override uri = 'v1/signatures';

  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  public createAndUpload(signature: Signature): Observable<any> {
    const { url = '', ...detail } = signature;
    const formData = new FormData();
    formData.append('files[]', url);
    formData.append("body", JSON.stringify(detail));
    return this.httpClient.post<any>(
      `${this.apiUlr}${this.uri}`, formData,
    );
  }

  public editAndUpload(id: number, signature: Signature): Observable<any> {
    const { url = '', ...detail } = signature;
    const formData = new FormData();
    formData.append('files[]', url);
    formData.append("body", JSON.stringify(detail));
    return this.httpClient.patch<any>(
      `${this.apiUlr}${this.uri}/${id}`, formData,
    );
  }


}
