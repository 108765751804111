import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavigationRoute } from '../../shared/constant/navigation-route.constant';
import { AuthGuard } from '../../core/guard/auth.guard';
import { AdminComponent } from './component/admin.component';
import { ClientsComponent } from './component/clients/clients.component';
import { UsersComponent } from './component/users/users.component';
import { SignaturesComponent } from './component/signatures/signatures.component';
import { DomainsComponent } from './component/domains/domains.component';
import { AdminGuard } from 'src/app/core/guard/admin.guard';


const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: NavigationRoute.CLIENTS,
        component: ClientsComponent
      },
      {
        path: NavigationRoute.USERS,
        component: UsersComponent
      },
      {
        path: NavigationRoute.SIGNATURES,
        component: SignaturesComponent
      },
      {
        path: NavigationRoute.DOMAIN,
        component: DomainsComponent
      }
    ],
    canActivate: [AuthGuard, AdminGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
