<div class="navbar navbar-dark bg-primary justify-content-between p-2">
    <span class="navbar-brand" style="color: white !important;">
        <img class="img-logo" src="../../../../assets/images/logo-header.png" alt="" />
    </span>
    <form class="form-inline">
        <div ngbDropdown class="d-flex align-items-center">
            <div class="mr-3">
                <button type="button" class="btn btn-light">
                    <a 
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdBBNpMBTA-mHQbsy-E8DUNVllZ9XlGG2BfKQ27m7Zmw0REjg/viewform"
                        target="_blank">
                        Calificanos
                    </a>
                </button>
            </div>
            <div class="mr-3">
                <span style="color: white;">{{userName}}</span>
            </div>
            <div class="foto-perfil" ngbDropdownToggle>
                <div class="circulo-act"></div>
                <img src="../../../../assets/images/photo.jpg" alt="">
            </div>
            <div ngbDropdownMenu aria-labelledby=" dropdownBasic1 ">
                <button ngbDropdownItem (click)="onLogout()">
                    Cerrar sesión
                </button>
            </div>
        </div>
    </form>
</div>