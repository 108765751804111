<form [formGroup]="formGroup" *ngIf="formGroup" autocomplete="off" novalidate #formRef="ngForm">
  <div class="modal-header">
    <h5 class="card-title">
      Editar mis datos
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="client=null;activeModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card shaded-card">
    <div class="card-body container-fluid">

      <div class="row">
        <div class="form-group col">
          <label>Nit</label>
          <input type="text" class="form-control" formControlName="identificationNumber"
            [ngClass]="{ 'is-invalid': controls['identificationNumber'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['identificationNumber'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
        <div class="form-group col">
          <label>Razón social</label>
          <input type="text" class="form-control" formControlName="name"
            [ngClass]="{ 'is-invalid': controls['name'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['name'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col">
          <label>Teléfono</label>
          <input type="text" class="form-control" formControlName="phone"
            [ngClass]="{ 'is-invalid': controls['phone'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['phone'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>

        <div class="form-group col">
          <label>Correo electrónico</label>
          <input type="email" class="form-control" formControlName="email"
            [ngClass]="{ 'is-invalid': controls['email'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['email'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col">
          <label>Contacto</label>
          <input type="text" class="form-control" formControlName="contact"
            [ngClass]="{ 'is-invalid': controls['contact'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['contact'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>

        <div class="form-group col">
          <label>Teléfono de contacto</label>
          <input type="text" class="form-control" formControlName="contactPhone"
            [ngClass]="{ 'is-invalid': controls['contactPhone'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['contactPhone'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>


    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="client=null;activeModal.close()">
      Cancelar
    </button>
    <button type="submit" class="btn btn-primary" (click)="onSave()">
      Guardar
    </button>
  </div>
</form>