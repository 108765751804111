import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Pagination } from 'src/app/feature/result/model/pagination';
import { Domain } from '../../model/domain';
import { DomainApiService } from '../../service/domain-api.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomainFormComponent } from './domain-form/domain-form.component';
import { Option } from 'src/app/shared/model/option';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.css']
})
export class DomainsComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public formGroup: FormGroup;

  public totalRecords: number;
  public pageSizes = [10, 15, 30, 50, 100];
  public pagination: Pagination;

  public domains: Array<Domain>;


  constructor(
    private domainApiService: DomainApiService,
    private alertService: AlertService,
    private modalService: NgbModal
  ) {
    this.pagination = { page: 1, recordPerPage: 10 };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {

    this.initForm();
    this.onFind();
  }

  private initForm() {
    this.formGroup = new FormGroup({
      name: new FormControl(null),
      parameter: new FormControl(null),
      active: new FormControl(null),
    });
  }

  public onFind() {
    const parameter = this.formGroup.get('parameter').value;
    const active = this.formGroup.get('active').value;
    const name = this.formGroup.get('name').value;
    const sub = this.domainApiService.findParameter(
      this.pagination,
      parameter,
      active,
      name
    ).subscribe(resp => {
      this.domains = resp.content;
      this.totalRecords = resp.totalRecord;
    });
    this.subscription.add(sub);
  }


  public handlePageChange(event: number): void {
    this.pagination.page = event;
    this.onFind();
  }

  public handlePageSizeChange(event: any): void {
    this.pagination.page = 1;
    this.pagination.recordPerPage = event.target.value;
    this.onFind();
  }

  public onDelete(domain: Domain) {
    this.alertService.showConfirmation('¿Está seguro que desea inactivar este regitro?').then(resp => {
      if (resp.value) {
        this.domainApiService.delete(domain.id).subscribe(() => {
          this.alertService.showSuccessful('El domaine se ha inactivado correctamente', 'Inactivación exitosa')
          this.onFind();
        })
      }
    });
  }

  public onOpenForm(domain?: Domain) {
    const modalRef = this.modalService.open(DomainFormComponent, {
      backdrop: 'static'
    })
    modalRef.componentInstance.domain = domain;
    modalRef.result.then((result) => {
      if (result) {
        this.onFind();
      }
    })
  }

  public getNameDescription(name: string) {
    return this.options.find(o => o.value == name)?.label;
  }

  private options: Array<Option> = [
    {
      value:'RESTRICTED_COMPANY_CODES',
      label: 'Empresas - Restriccion'
    },
    {
      value:'DOM_EXAM_SPECIAL_COMPANY',
      label: 'Examenes por Empresa'
    },
    {
      value:'DOM_EXAM_SPECIAL',
      label: 'Examenes Restringidos'
    },
    {
      value:'DOM_DATE_LIMIT',
      label: 'Fecha Limite descarga'
    },
    {
      value:'DOM_COMPANY_TOTAL',
      label: 'Empresa - Valor req.'
    },
    {
      value:'identificationtype',
      label: 'Tipo Identificación'
    }
  ]

}
