import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Signature } from '../../../model/signature';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SignatureApiService } from '../../../service/signature-api.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileApiService } from 'src/app/shared/service/file-api.service';

@Component({
  selector: 'app-signature-form',
  templateUrl: './signature-form.component.html'
})
export class SignatureFormComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  @Input()
  public signature: Signature;
  public formGroup: FormGroup;
  public url: string;

  constructor(
    private signatureApiService: SignatureApiService,
    private alertService: AlertService,
    public activeModal: NgbActiveModal,
    private fileApiService: FileApiService
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.formGroup.reset();
    this.signature = null;
    this.subscription.unsubscribe();
  }

  private initForm() {

    this.formGroup = new FormGroup({
      code: new FormControl(this.signature?.code, [Validators.required]),
      url: new FormControl(this.signature?.url, [Validators.required]),
      active: new FormControl(this.signature?.active, [Validators.required]),
      currentUrl: new FormControl(this.signature?.url),
    });
    if (this.signature) {
       const sub = this.fileApiService.findByPath(this.signature.url).subscribe(resp => {
        this.blobToFile(resp, 'firma.jpg');
      });
      this.subscription.add(sub);
    }
  }

  private blobToFile(theBlob: Blob, fileName: string) {
    const myFile = new File([theBlob], fileName, {
      type: theBlob.type,
  });
    this.formGroup.patchValue({
      url: myFile
    });
    const readerHeader = new FileReader();
    readerHeader.onload = () => {
      this.url = readerHeader.result as string;
    }
    readerHeader.readAsDataURL(myFile)
  }


  public get controls() {
    return this.formGroup.controls;
  }

  public onSave() {
    if (this.formGroup.invalid) {
      return;
    }
    const signature = this.formGroup.getRawValue();
    if (this.signature) {
      this.update(signature);
    } else {
      this.save(signature);
    }
  }

  private save(signature: Signature) {
    const sub = this.signatureApiService.createAndUpload(signature).subscribe(() => {
      this.alertService.showSuccessful('Se ha creado la firma correctamente', 'Creado exito!');
      this.signature = null;
      this.activeModal.close(true);
    });
    this.subscription.add(sub);
  }

  private update(signature: Signature) {
    const sub = this.signatureApiService.editAndUpload(this.signature.id, signature).subscribe(() => {
      this.alertService.showSuccessful('Se ha actualizado la firma correctamente', 'Actualizado exito!');
      this.signature = null;
      this.activeModal.close(true);
    });
    this.subscription.add(sub);
  }

  public showHeaderPreview(event) {
    const file = event.target.files[0];
    this.formGroup.patchValue({
      url: file
    });
    this.formGroup.get('url').updateValueAndValidity()
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.url = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

}
