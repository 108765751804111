import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DomainNames } from 'src/app/shared/enum/domain-names.enum';
import { Domain } from 'src/app/shared/model/domain';
import { User } from 'src/app/shared/model/user';
import { AuthStorageService } from 'src/app/shared/service/auth-storage.service';
import { DomainFinderApiService } from 'src/app/shared/service/domain-finder-api.service';
import { ResultApiService } from '../../service/result-api.service';
import { Patient } from '../../model/patient';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '../../../../shared/service/alert.service';
import { Router } from '@angular/router';
import { NavigationRoute } from 'src/app/shared/constant/navigation-route.constant';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-patients-info',
  templateUrl: './patients-info.component.html'
})
export class PatientsInfoComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  public formGroup: FormGroup;
  public indentificationTypes: Array<Domain>;
  public user: User;
  public patients: Array<Patient>;
  private routes = NavigationRoute;
  public page = 1;
  public pageSize = 10;
  public pageSizes = [10, 15, 30, 50, 100];

  constructor(
    private calendar: NgbCalendar,
    private domainApiService: DomainFinderApiService,
    private authStorageService: AuthStorageService,
    private resultApiService: ResultApiService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit() {
    this.user = this.authStorageService.getAuthenticatedUser();
    this.page = 1;
    this.initForm();
    this.findIdentificationTypes()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initForm() {
    this.formGroup = new FormGroup({
      startDate: new FormControl(),
      endDate: new FormControl(),
      identificationType: new FormControl(null),
      identificationNumber: new FormControl(null),
      name: new FormControl(null),
      lastName: new FormControl(null),
    });
  }

  private findIdentificationTypes() {
    const sub = this.domainApiService.findActivesByName(DomainNames.IDENTIFICATION_TYPE).subscribe(resp => {
      this.indentificationTypes = resp;
    });
    this.subscription.add(sub);
  }

  public get controls() {
    return this.formGroup.controls;
  }

  public onFromDateSelection(date: NgbDate) {
    this.formGroup.get('startDate').setValue(date);
  }

  public onToDateSelection(date: NgbDate) {
    this.formGroup.get('endDate').setValue(date);
  }

  public onFind() {
    if (this.formGroup.invalid) {
      return;
    }
    const filter = this.formGroup.getRawValue();
    const result = Object.values(filter).every(o => o === null);
    if (result) {
      this.alertService.showWarning("Debe seleccionar por lo menos un filtro", "Importante!");
      return;
    }
    const startDate: NgbDate = this.toNgbDate(this.formGroup.get('startDate').value);
    const endDate: NgbDate = this.toNgbDate(this.formGroup.get('endDate').value);
    if ((startDate && !endDate) || (endDate && !startDate)) {
      this.alertService.showWarning("Debe seleccionar un rango de fecha valido", "Importante!");
      return;
    }
    if (startDate && endDate && startDate.after(endDate)) {
      this.alertService.showWarning("Debe seleccionar un rango de fecha valido", "Importante!");
      return;
    }
    filter.startDate = this.getFormatedDate(startDate);
    filter.endDate = this.getFormatedDate(endDate);
    const sub = this.resultApiService.findByParameters(filter).subscribe(resp => {
      this.patients = resp;
      if (resp.length == 0) {
        this.alertService.showWarning("No se ha encontrado resultados con los datos ingresados", "Importante!");
      }
    });
    this.subscription.add(sub);
  }

  private getFormatedDate(date: NgbDate) {
    if (!date) {
      return null;
    }
    return date.year + '-'
      + (date.month > 9 ? date.month : '0' + date.month) + '-'
      + (date.day > 9 ? date.day : '0' + date.day)
  }

  private toNgbDate(date) {
    if (!date) {
      return null;
    }
    return new NgbDate(date.year, date.month, date.day)
  }


  public async onGoToPatientExams(patient: Patient) {
    await this.router.navigate(
      [this.routes.RESULT, this.routes.PATIENT_EXAMS],
      {
        queryParams: {
          identificationType: patient.identificacionType,
          identificationNumber: patient.identificationNumber
        }
      }
    );
  }
  
  handlePageChange(event: number): void {
    this.page = event;
  }

  handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

}
