<form [formGroup]="formGroup" [formGroup]="formGroup" *ngIf="formGroup" autocomplete="off" novalidate #formRef="ngForm">
  <div class="modal-header">
    <h5 class="card-title" *ngIf="domain">
      Editar
    </h5>
    <h5 class="card-title" *ngIf="!domain">
      Crear
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="domain=null;activeModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card shaded-card">
    <div class="card-body container-fluid">


      <div class="row">
        <div class="form-group col">
          <label>Nombre</label>
          <select class="form-control form-select" formControlName="name"
            [ngClass]="{ 'is-invalid': controls['name'].errors && formRef.submitted }">
            <option [ngValue]="null">Seleccione</option>
            <option value="RESTRICTED_COMPANY_CODES">Empresas - Restriccion</option>
            <option value="DOM_EXAM_SPECIAL_COMPANY">Examenes por Empresa</option>
            <option value="DOM_EXAM_SPECIAL">Examenes Restringidos</option>
            <option value="DOM_DATE_LIMIT">Fecha Limite descarga</option>
            <option value="DOM_COMPANY_TOTAL">Empresa - Valor req.</option>
            <option value="identificationtype">Tipo Identificación</option>
          </select>
          <div *ngIf="formRef.submitted && controls['name'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col">
          <label>Titulo</label>
          <input type="text" class="form-control" formControlName="title"
            [ngClass]="{ 'is-invalid': controls['title'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['title'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col">
          <label>Valor</label>
          <textarea class="form-control" formControlName="value" rows="3"
            [ngClass]="{ 'is-invalid': controls['value'].errors && formRef.submitted }"> </textarea>
          <div *ngIf="formRef.submitted && controls['value'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>


      <div class="row">

        <div class="form-group col">
          <label>Estado</label>
          <select class="form-control form-select" formControlName="active"
            [ngClass]="{ 'is-invalid': formRef.submitted && controls['active'].errors }">
            <option [ngValue]="null">Seleccione</option>
            <option [ngValue]="true">Activo</option>
            <option [ngValue]="false">Inactivo</option>
          </select>
          <div *ngIf="formRef.submitted && controls['active'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="domain=null;activeModal.close()">
      Cancelar
    </button>
    <button type="submit" class="btn btn-primary" (click)="onSave()">
      Guardar
    </button>
  </div>
</form>