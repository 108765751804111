<main class="h-100">
  <app-header></app-header>
  <section class="d-flex h-100">
    <app-sidebar [isExpanded]="sidebarExpanded" (toggleSidebar)="sidebarExpanded = !sidebarExpanded"></app-sidebar>
    <div class="content" [ngClass]="{ 'sidebarExpanded': sidebarExpanded }">
      <router-outlet></router-outlet>
    </div>
  </section>
  <a  class="float" placement="top" ngbTooltip="Calificanos"
    href="https://docs.google.com/forms/d/e/1FAIpQLSdBBNpMBTA-mHQbsy-E8DUNVllZ9XlGG2BfKQ27m7Zmw0REjg/viewform"
    target="_blank">
    <i class="bi bi-star-fill my-float"></i>
  </a>
</main>