import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { take, tap } from 'rxjs';
import { AuthStorageService } from '../../shared/service/auth-storage.service';
import { NavigationRoute } from '../../shared/constant/navigation-route.constant';
import { Actions } from 'src/app/shared/enum/actions.enum';


export const AdminGuard = () => {
  const authStorageService = inject(AuthStorageService);
  const router = inject(Router);
  const user = authStorageService.getAuthenticatedUser();
  const actions: Array<string> = [Actions.CLIENT, Actions.PATIENT, Actions.SIGN, Actions.DOMAIN]
  return user.actions.some(a => actions.includes(a)) ? true : router.navigate([NavigationRoute.EMPTY]);
}