import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from '../../../shared/service/base-api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../../result/model/pagination';
import { Page } from 'src/app/shared/model/page';
import { User } from 'src/app/shared/model/user';

@Injectable()
export class UserApiService extends BaseApiService<User>  {

  protected override uri = 'v1/users';

  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  public findParameter(pagination: Pagination, parameter?: string): Observable<Page<User>> {
    const { recordPerPage, page } = pagination;
    const params =`?recordPerPage=${recordPerPage}&page=${page}` + (parameter ? `&parameter=${parameter}` : '');
    return this.httpClient.get<Page<User>>(
      `${this.apiUlr}${this.uri}${params}`
    );
  }

}
