import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from 'src/app/shared/model/user';

@Injectable()
export class PatientApiService {

  protected apiUrl = environment.apiUrl + 'v1/patients';

  constructor(private httpClient: HttpClient) { }

  public findByIdentification(identificationType: string, identificationNumber: string): Observable<User> {
    return this.httpClient.get<User>(
      `${this.apiUrl}?identificationType=${identificationType}&identificationNumber=${identificationNumber}`
    );
  }


  public validateIfPaid(requestCode: string): Observable<User> {
    return this.httpClient.post<User>(
      `${this.apiUrl}/validate?requestCode=${requestCode}`, {}
    );
  }


}
