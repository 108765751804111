import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Client } from 'src/app/feature/admin/model/client';
import { ClientApiService } from 'src/app/feature/admin/service/client-api.service';
import { AlertService } from 'src/app/shared/service/alert.service';

@Component({
  selector: 'app-update-client',
  templateUrl: './update-client.component.html'
})
export class UpdateClientComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  @Input()
  public client: Client;
  public formGroup: FormGroup;
  public headerUrl: string;
  public footerUrl: string;

  constructor(private clientApiService: ClientApiService,
    private alertService: AlertService,
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.formGroup.reset();
    this.client = null;
    this.subscription.unsubscribe();
  }

  private initForm() {

    this.formGroup = new FormGroup({
      identificationNumber: new FormControl(this.client?.identificationNumber, [Validators.required]),
      name: new FormControl(this.client?.name, [Validators.required]),
      phone: new FormControl(this.client?.phone, [Validators.required]),
      email: new FormControl(this.client?.email, [Validators.required, Validators.email]),
      contact: new FormControl(this.client?.contact),
      contactPhone: new FormControl(this.client?.contactPhone),
    });
  }

  public get controls() {
    return this.formGroup.controls;
  }

  public onSave() {
    if (this.formGroup.invalid) {
      return;
    }
    const client = this.formGroup.getRawValue();
    this.update(client);
  }

  private update(client: Client) {
    const sub = this.clientApiService.editPartial(this.client.id, client).subscribe(() => {
      this.setStorage(client)
      this.alertService.showSuccessful('Se ha actualizado su información correctamente', 'Actualizado exito!');
      this.activeModal.close(this.client);
    });
    this.subscription.add(sub);
  }

  private setStorage(client: Client) {
    this.client.identificationNumber = client.identificationNumber;
    this.client.name = client.name;
    this.client.phone = client.phone;
    this.client.email = client.email;
    this.client.contact = client.contact;
    this.client.contactPhone = client.contactPhone;
  }

}
