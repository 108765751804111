import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthStorageService } from 'src/app/shared/service/auth-storage.service';

@Component({
  selector: 'app-user-duties-and-rights',
  templateUrl: './user-duties-and-rights.component.html'
})
export class UserDutiesAndRightsComponent implements OnInit {


  constructor(
    private authStorageService: AuthStorageService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
  }

  close() {
    this.authStorageService.setDutiesAndRights();
    this.activeModal.close();
  }

}
