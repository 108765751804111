import { Injectable } from '@angular/core';
import { Client } from '../model/client';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from '../../../shared/service/base-api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../../result/model/pagination';
import { Page } from 'src/app/shared/model/page';

@Injectable()
export class ClientApiService extends BaseApiService<Client>  {

  protected override uri = 'v1/clients';

  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  public findParameter(pagination: Pagination, parameter?: string): Observable<Page<Client>> {
    const { recordPerPage, page } = pagination;
    const params =`?recordPerPage=${recordPerPage}&page=${page}` + (parameter ? `&parameter=${parameter}` : '');
    return this.httpClient.get<Page<Client>>(
      `${this.apiUlr}${this.uri}${params}`
    );
  }

  public createAndUpload(client: Client): Observable<any> {
    const { urlImage = '', footer = '',...detail } = client;
    const formData = new FormData();
    formData.append('files[]', urlImage);
    formData.append('files[]', footer);
    formData.append("body", JSON.stringify(detail));
    return this.httpClient.post<any>(
      `${this.apiUlr}${this.uri}`, formData,
    );
  }

  public editAndUpload(id: number, client: Client): Observable<any> {
    const { urlImage = '', footer = '',...detail } = client;
    const formData = new FormData();
    formData.append('files[]', urlImage);
    formData.append('files[]', footer);
    formData.append("body", JSON.stringify(detail));
    return this.httpClient.patch<any>(
      `${this.apiUlr}${this.uri}/${id}`, formData,
    );
  }

  public editPartial(id: number, client: Client): Observable<any> {
    return this.httpClient.put<any>(
      `${this.apiUlr}${this.uri}/${id}`, client
    );
  }

}
