<div class="modal-header">
  <h4 class="modal-title">Conoce tus derechos y deberes</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
  <h5>Derechos</h5>
  <ul>
    <li>Ser atendido sin restricción de religión, opinión política, raza, género, edad, idioma y origen social.</li>
    <li>Recibir información clara y completa sobre procedimientos a realizar y los riesgos asociados.</li>
    <li>Comunicar quejas, sugerencias y felicitaciones.</li>
    <li>Ser atendido oportunamente, con respeto y amabilidad.</li>
  </ul>
  <h5>Deberes</h5>
  <ul>
    <li>Presentar documento de identidad y/o carné que lo acredite como beneficiario del servicio y solicitudes médicas.</li>
    <li>Suministrar información veraz, clara y completa solicitada por nuestro personal.</li>
    <li>Brindar un trato cordial y respetuoso al personal que lo atiende.</li>
    <li>Cumplir con las condiciones para la realización de los exámenes.</li>
  </ul>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">Entendido</button>
</div>
