import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './component/admin.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { AdminRoutingModule } from './admin-routing.module';
import { ClientsComponent } from './component/clients/clients.component';
import { UsersComponent } from './component/users/users.component';
import { ClientApiService } from './service/client-api.service';
import { ClientFormComponent } from './component/clients/client-form/client-form.component';
import { UserFormComponent } from './component/users/user-form/user-form.component';
import { UserApiService } from './service/user-api.service';
import { SignaturesComponent } from './component/signatures/signatures.component';
import { SignatureApiService } from './service/signature-api.service';
import { SignatureFormComponent } from './component/signatures/signature-form/signature-form.component';
import { DomainsComponent } from './component/domains/domains.component';
import { DomainFormComponent } from './component/domains/domain-form/domain-form.component';
import { DomainApiService } from './service/domain-api.service';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    AdminRoutingModule,
    NgxPaginationModule
  ],
  declarations: [
    AdminComponent,
    ClientsComponent,
    UsersComponent,
    ClientFormComponent,
    UserFormComponent,
    SignaturesComponent,
    SignatureFormComponent,
    DomainsComponent,
    DomainFormComponent
  ],
  providers: [
    ClientApiService,
    UserApiService,
    SignatureApiService,
    DomainApiService
  ]
})
export class AdminModule { }
