<form [formGroup]="formGroup" *ngIf="formGroup" autocomplete="off" novalidate #formRef="ngForm">
  <div class="modal-header">
    <h5 class="card-title" *ngIf="signature">
      Editar
    </h5>
    <h5 class="card-title" *ngIf="!signature">
      Crear
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="signature=null;activeModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card shaded-card">
    <div class="card-body container-fluid">

      <div class="row">
        <div class="form-group col">
          <label>Código</label>
          <input type="text" class="form-control" formControlName="code"
            [ngClass]="{ 'is-invalid': controls['code'].errors && formRef.submitted }"/>
          <div *ngIf="formRef.submitted && controls['code'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col">
          <div class="custom-file">
            <input type="file" accept="image/*" class="custom-file-input form-control"
              (change)="showHeaderPreview($event)"
              [ngClass]="{ 'is-invalid': formRef.submitted && controls['url'].errors }" />
            <label class="custom-file-label">Seleccione firma</label>
            <div *ngIf="formRef.submitted && controls['url'].errors" class="invalid-feedback">
              Este campo es requerido
            </div>
          </div>
          <!-- Image Preview -->
          <div class="mt-2" *ngIf="url && url !== ''">
            <img class="firma-preview" [src]="url" [alt]="">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col">
          <label>Estado</label>
          <select class="form-control form-select" formControlName="active"
            [ngClass]="{ 'is-invalid': formRef.submitted && controls['active'].errors }">
            <option [ngValue]="null">Seleccione</option>
            <option [ngValue]="true">Activo</option>
            <option [ngValue]="false">Inactivo</option>
          </select>
          <div *ngIf="formRef.submitted && controls['active'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="signature=null;activeModal.close()">
      Cancelar
    </button>
    <button type="submit" class="btn btn-primary" (click)="onSave()">
      Guardar
    </button>
  </div>
</form>