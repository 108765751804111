<div class="row d-flex align-items-center">
  <div class="col">
    <h3>Configuraciones</h3>
  </div>
</div>
<hr />
<form [formGroup]="formGroup" autocomplete="off" novalidate #formRef="ngForm">
  <div class="row mt-2 align-items-center">
    <div class="form-group col">
      <select class="form-control form-select" formControlName="name">
        <option [ngValue]="null">Seleccione</option>
        <option value="RESTRICTED_COMPANY_CODES">Empresas - Restriccion</option>
        <option value="DOM_EXAM_SPECIAL_COMPANY">Examenes por Empresa</option>
        <option value="DOM_EXAM_SPECIAL">Examenes Restringidos</option>
        <option value="DOM_DATE_LIMIT">Fecha Limite descarga</option>
        <option value="DOM_COMPANY_TOTAL">Empresa - Valor req.</option>
        <option value="identificationtype">Tipo Identificación</option>
      </select>
    </div>
    <div class="form-group col">
      <select class="form-control form-select" formControlName="active">
        <option [ngValue]="null">Seleccione</option>
        <option [ngValue]="true">Activo</option>
        <option [ngValue]="false">Inactivo</option>
      </select>
    </div>
    <div class="col-5 form-group ">
      <div class="input-group">
        <input type="test" class="form-control" placeholder="Buscar" formControlName="parameter">
        <div class="input-group-append">
          <button class="btn btn-default" type="button" (click)="onFind()">
            <img src="../../../../../assets/images/search.svg" alt="">
          </button>
        </div>
      </div>
    </div>
    <div class="col  form-group d-flex justify-content-end align-items-center">
      <button type="button" class="btn btn-primary" (click)="onOpenForm()">
        Nuevo
      </button>
    </div>
  </div>
</form>
<div class="row mt-2">
  <div class="col" style="overflow-x:auto;">
    <table class="table" aria-describedby="">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nombre</th>
          <th scope="col">Titulo</th>
          <th scope="col">Valor</th>
          <th scope="col">Estado</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let domain of domains | paginate: { itemsPerPage: pagination.recordPerPage, currentPage: pagination.page,  totalItems: totalRecords }; let index=index;">
          <td>{{ 10 * (pagination.page - 1) + (index+1) }}</td>
          <td>{{ getNameDescription(domain.name) }}</td>
          <td>{{domain.title}}</td>
          <td>{{domain.value}}</td>
          <td>{{domain.active ? 'Activo' : 'Inactivo'}}</td>
          <td>
            <div ngbDropdown class="d-inline-block">
              <button class="btn dropdown-actions" id="dropdownBasic1" ngbDropdownToggle>
                ...
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="onOpenForm(domain)">
                  Editar
                </button>
                <button ngbDropdownItem (click)="onDelete(domain)">
                  Inactivar
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row">
  <div class="col">
    <hr />
  </div>
</div>
<div class="row" *ngIf="totalRecords > 0">
  <div class="col d-flex justify-content-end align-items-center">
    Registros por pagina:
    <select class="form-control m-2" style="width: auto;" (change)="handlePageSizeChange($event)">
      <option *ngFor="let size of pageSizes" [ngValue]="size">
        {{ size }}
      </option>
    </select>
    <pagination-controls (pageChange)="handlePageChange($event)" previousLabel="Ant." nextLabel="Sig."
      [directionLinks]="true" class="mt-3">
    </pagination-controls>
    <span class="mt-1 ml-3"><strong>Total: </strong>{{totalRecords}}</span>
  </div>
</div>