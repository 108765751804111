import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { Client } from '../../../model/client';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientApiService } from '../../../service/client-api.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileApiService } from '../../../../../shared/service/file-api.service';

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html'
})
export class ClientFormComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  @Input()
  public client: Client;
  public formGroup: FormGroup;
  public headerUrl: string;
  public footerUrl: string;

  constructor(private clientApiService: ClientApiService,
    private alertService: AlertService,
    public activeModal: NgbActiveModal,
    private fileApiService: FileApiService
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.formGroup.reset();
    this.client = null;
    this.subscription.unsubscribe();
  }

  private initForm() {

    this.formGroup = new FormGroup({
      code: new FormControl(this.client?.code, [Validators.required]),
      identificationNumber: new FormControl(this.client?.identificationNumber, [Validators.required]),
      name: new FormControl(this.client?.name, [Validators.required]),
      phone: new FormControl(this.client?.phone, [Validators.required]),
      password: new FormControl(this.client?.password, [Validators.required]),
      password2: new FormControl(this.client?.password, [Validators.required]),
      email: new FormControl(this.client?.email, [Validators.required, Validators.email]),
      contact: new FormControl(this.client?.contact),
      contactPhone: new FormControl(this.client?.contactPhone),
      downloadOption: new FormControl(this.client?.downloadOption, [Validators.required]),
      logoOption: new FormControl(this.client?.logoOption, [Validators.required]),
      footer: new FormControl(this.client?.footer),
      urlImage: new FormControl(this.client?.urlImage),
      active: new FormControl(this.client?.active, [Validators.required]),
      currentHeader: new FormControl(this.client?.urlImage),
      currentFooter: new FormControl(this.client?.footer)
    });
    this.formGroup.setValidators(this.checkPasswords);
    if (this.client && this.client.urlImage && this.client.footer) {
      const sub = forkJoin({
        header: this.fileApiService.findByPath(this.client.urlImage),
        footer: this.fileApiService.findByPath(this.client.footer),
      }).subscribe(resp => {
        this.blobToFile(resp.footer, 'footer.jpg', 'footer', true);
        this.blobToFile(resp.header, 'header.jpg', 'urlImage', false);
      });
      this.subscription.add(sub);
    }
  }

  private blobToFile(theBlob: Blob, fileName: string, fieldKey: string, isFooter: boolean) {
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    const finalFile = b as File;
    const myFile = new File([theBlob], fileName, {
      type: theBlob.type,
  });
    this.formGroup.patchValue({
      [fieldKey]: myFile
    });
    const readerHeader = new FileReader();
    readerHeader.onload = () => {
      if (isFooter) {
        this.footerUrl = readerHeader.result as string;
      } else {
        this.headerUrl = readerHeader.result as string;
      }
    }
    readerHeader.readAsDataURL(myFile)
  }

  private checkPasswords(group: FormGroup) {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('password2')?.value;
    if (password && confirmPassword) {
      const equals = password === confirmPassword;
      if (!equals) {
        group.get('password').setErrors({ 'required': true });
        group.get('password2').setErrors({ 'required': true });
      } else {
        group.get('password').setErrors(null)
        group.get('password2').setErrors(null)
      }
      return equals ? null : { notSame: true };
    } else {
      return null;
    }
  }

  public get controls() {
    return this.formGroup.controls;
  }

  public onSave() {
    console.log('this.formGroup ', this.formGroup);
    if (this.formGroup.invalid) {
      return;
    }
    const client = this.formGroup.getRawValue();
    if (this.client) {
      this.update(client);
    } else {
      this.save(client);
    }
  }

  private save(client: Client) {
    const sub = this.clientApiService.createAndUpload(client).subscribe(() => {
      this.alertService.showSuccessful('Se ha creado el cliente correctamente', 'Creado exito!');
      this.client = null;
      this.activeModal.close(true);
    });
    this.subscription.add(sub);
  }

  private update(client: Client) {
    const sub = this.clientApiService.editAndUpload(this.client.id, client).subscribe(() => {
      this.alertService.showSuccessful('Se ha actualizado el cliente correctamente', 'Actualizado exito!');
      this.client = null;
      this.activeModal.close(true);
    });
    this.subscription.add(sub);
  }

  public showHeaderPreview(event) {
    const file = event.target.files[0];
    this.formGroup.patchValue({
      urlImage: file
    });
    this.formGroup.get('urlImage').updateValueAndValidity()
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.headerUrl = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  public showFooterPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.formGroup.patchValue({
      footer: file
    });
    this.formGroup.get('footer').updateValueAndValidity()
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.footerUrl = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  public onSelectLogoOption() {
    const selected = this.formGroup.get('logoOption').value;
    console.log('selected ', selected);
    if (selected == 'logo_empresa') {
      this.formGroup.get('urlImage').setValidators([Validators.required]);
      this.formGroup.get('footer').setValidators([Validators.required]);
    } else {
      this.formGroup.get('urlImage').setValidators([]);
      this.formGroup.get('footer').setValidators([]);
    }
  }


}
