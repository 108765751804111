import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ChangePasswordService {

  protected apiUlr = environment.apiUrl+'v1/auth/';

  constructor(private httpClient: HttpClient) { }

  public changePassword(value): Observable<any> {
    return this.httpClient.post<any>(
      `${this.apiUlr}change-password`, value
    );
  }

}
