import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationRoute } from '../../constant/navigation-route.constant';
import { AuthStorageService } from '../../service/auth-storage.service';
import { User } from '../../model/user';
import { UserTypes } from '../../enum/user-types.enum';
import { Actions } from '../../enum/actions.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdatePatientComponent } from '../update-user/update-patient/update-patient.component';
import { UpdateClientComponent } from '../update-user/update-client/update-client.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  public routes = NavigationRoute;
  private user: User;
  public types = UserTypes;
  public actions = Actions;

  constructor(
    private authStorageService: AuthStorageService,
    private modalService: NgbModal
  ) {
    this.user = authStorageService.getAuthenticatedUser();
  }

  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);


  public isVisible(action: string) {
    return this.user.actions.some(r => r == action);
  }

  public onOpenPatientForm() {
    const modalRef = this.modalService.open(UpdatePatientComponent, {
      backdrop: 'static',
      size: 'lg'
    })
    modalRef.componentInstance.user = this.user;
    modalRef.result.then((result: User) => {
      if(result) {
        this.user = result;
        this.authStorageService.setUser(this.user);
        location.reload();
      }

    });
  }

  public onOpenClientForm() {
    const modalRef = this.modalService.open(UpdateClientComponent, {
      backdrop: 'static',
      size: 'lg'
    })
    modalRef.componentInstance.client = this.user;
    modalRef.result.then((result) => {
      if(result) {
        this.user = result;
        this.authStorageService.setUser(this.user);
        location.reload();
      }
    });
  }

  public onChangePassword(e:Event) {
    const modalRef = this.modalService.open(ChangePasswordComponent, {
      backdrop: 'static'
    });
    modalRef.result.then(() => {
    })
    e.stopPropagation()
  }


}
