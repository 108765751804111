<div class="row">
  <div class="col d-flex justify-content-center">
    <table class="table" aria-describedby="" style="width: 92%;">
      <thead class="v-heading">
        <tr>
          <th scope="col" colspan="2">Datos personales</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="width: 15%;">Nombre</td>
          <td>{{user.name}} {{user.lastName}}</td>
        </tr>
        <tr>
          <td>Indentificación</td>
          <td>{{user.identificationNumber}}</td>
        </tr>
        <tr>
          <td>Dirección</td>
          <td>{{user.address}}</td>
        </tr>
        <tr>
          <td>Teléfono</td>
          <td>{{user.phone}}</td>
        </tr>
        <tr>
          <td>Sexo</td>
          <td>{{user.sex}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row">
  <div class="col">
    <hr>
  </div>
</div>
<div class="row mt-3">
  <div class="col" style="overflow-x:auto;">
    <table class="table" aria-describedby="">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Solicitud</th>
          <th scope="col">Fecha recepción</th>
          <th scope="col">Examenes</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let patientExam of patientExams;let index=index;">
          <td>{{index + 1}}</td>
          <td>{{patientExam.requestCode}}</td>
          <td>{{patientExam.receptionDate}}</td>
          <td>{{patientExam.exams}}</td>
          <td>
            <img src="../../../../../assets/images/download_icon.png" alt=""
              (click)="onDownloadResult(patientExam.requestCode, patientExam.url)">
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>