<div class="sidebar" [ngClass]="{ expanded: isExpanded }">
    <div class="header">
        <!--<img class="img-logo" [ngClass]="{ expanded: isExpanded }" src="../../../../assets/images/logo-sidebar.JPG"
            alt="" style="width: 90%;" />-->
        <div class="toggle" (click)="handleSidebarToggle()">
            <i class="bi bi-text-indent-left" [ngClass]="{ expanded: isExpanded }"></i>
            <i class="bi bi-text-indent-right" [ngClass]="{ expanded: isExpanded }"></i>
        </div>
    </div>
    <div class="content">
        <div class="link with-children">
            <div class="content" [routerLink]="[routes.RESULT, routes.PATIENT_EXAMS]"
                *ngIf="isVisible(actions.MY_RESULT)">
                <div class="icon"><i class="bi bi-person"></i></div>
                <div class="title">Mis resultados</div>
            </div>
        </div>
        <div class="link">
            <div class="content" (click)="onOpenClientForm()" *ngIf="isVisible(actions.UPDATE_CLIENT_DATA)">
                <div class="icon"><i class="bi bi-person-gear"></i></div>
                <div class="title">Editar mis datos</div>
            </div>
        </div>
        <div class="link">
            <div class="content" (click)="onOpenPatientForm()" *ngIf="isVisible(actions.UPDATE_PATIENT_DATA)">
                <div class="icon"><i class="bi bi-person-gear"></i></div>
                <div class="title">Editar mis datos</div>
            </div>
        </div>

        <div class="link with-children">
            <div class="content" [routerLink]="[routes.ADMIN, routes.USERS]" *ngIf="isVisible(actions.PATIENT)">
                <div class="icon"><i class="bi bi-person"></i></div>
                <div class="title">Pacientes</div>
            </div>
        </div>
        <div class="link with-children">
            <div class="content" [routerLink]="[routes.RESULT, routes.PATIENT_INFO]" *ngIf="isVisible(actions.RESULT)">
                <div class="icon"><i class="bi bi-file-text"></i></div>
                <div class="title">Resultados</div>
            </div>
        </div>

        <div class="link with-children">
            <div class="content" [routerLink]="[routes.ADMIN, routes.CLIENTS]" *ngIf="isVisible(actions.CLIENT)">
                <div class="icon"><i class="bi bi-hospital"></i></div>
                <div class="title">Clientes</div>
            </div>
        </div>

        <div class="link with-children">
            <div class="content" [routerLink]="[routes.ADMIN, routes.SIGNATURES]" *ngIf="isVisible(actions.SIGN)">
                <div class="icon"><i class="bi bi-clipboard-pulse"></i></div>
                <div class="title">Firmas</div>
            </div>
        </div>

        <div class="link with-children">
            <div class="content" [routerLink]="[routes.ADMIN, routes.DOMAIN]" *ngIf="isVisible(actions.DOMAIN)">
                <div class="icon"><i class="bi bi-gear"></i></div>
                <div class="title">Configuración</div>
            </div>
        </div>

        <div class="link">
            <div class="content" (click)="onChangePassword($event)">
                <div class="icon"><i class="bi bi-person-lock"></i></div>
                <div class="title">Cambiar Contraseña</div>
            </div>
        </div>

        <div class="link with-children">
            <div class="content">
                <div class="title"></div>
            </div>
        </div>
    </div>
    <div class="footer"></div>
</div>