<div class="row d-flex align-items-center">
  <div class="col">
    <h3>Firmas</h3>
  </div>
</div>
<hr/>
  <div class="row mt-2">
    <div class="col">
    </div>
    <div class="col d-flex justify-content-end">
      <button type="button" class="btn btn-primary" (click)="onOpenForm()">
        Nuevo
      </button>
    </div>
  </div>
<div class="row mt-2">
  <div class="col" style="overflow-x:auto;">
    <table class="table" aria-describedby="">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Código</th>
          <th scope="col">Url</th>
          <th scope="col">Estado</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let signature of signatures | paginate: { itemsPerPage: pageSize, currentPage: page }; let index=index;">
          <td>{{ pageSize * (page - 1) + (index+1) }}</td>
          <td>{{signature.code}}</td>
          <td>{{signature.url}}</td>
          <td>{{signature.active ? 'Activo' : 'Inactivo'}}</td>
          <td>
            <div ngbDropdown class="d-inline-block">
              <button class="btn dropdown-actions" id="dropdownBasic1" ngbDropdownToggle>
                ...
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem   (click)="onOpenForm(signature)">
                  Editar
                </button>
                <button ngbDropdownItem (click)="onDelete(signature)">
                  Inactivar
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row">
  <div class="col">
    <hr />
  </div>
</div>
<div class="row" *ngIf="signatures?.length > 0">
  <div class="col d-flex justify-content-end align-items-center">
    Registros por pagina:
    <select class="form-control m-2" style="width: auto;" (change)="handlePageSizeChange($event)">
      <option *ngFor="let size of pageSizes" [ngValue]="size">
        {{ size }}
      </option>
    </select>
    <pagination-controls (pageChange)="page = $event" previousLabel="Ant." nextLabel="Sig." [directionLinks]="true"
      class="mt-3">
    </pagination-controls>
    <span class="mt-1 ml-3"><strong>Total: </strong>{{signatures?.length}}</span>
  </div>
</div>