import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Signature } from '../../model/signature';
import { SignatureApiService } from '../../service/signature-api.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignatureFormComponent } from './signature-form/signature-form.component';

@Component({
  selector: 'app-signatures',
  templateUrl: './signatures.component.html'
})
export class SignaturesComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  public page = 1;
  public pageSize = 10;
  public pageSizes = [10, 15, 30, 50, 100];

  public signatures: Array<Signature>;


  constructor(
    private signatureApiService: SignatureApiService,
    private alertService: AlertService,
    private modalService: NgbModal
  ) { 
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.onFind();
  }

  public onFind() {
    const sub = this.signatureApiService.find(
    ).subscribe(resp => {
      this.signatures = resp;
    });
    this.subscription.add(sub);
  }


  public onDelete(signature: Signature) {
    this.alertService.showConfirmation('¿Está seguro que desea inactivar este regitro?').then(resp => {
      if (resp.value) {
        this.signatureApiService.delete(signature.id).subscribe(() => {
          this.alertService.showSuccessful('La firma se ha inactivado correctamente', 'Inactivación exitosa')
          this.onFind();
        })
      }
    });
  }

  public onOpenForm(signature?: Signature) {
    const modalRef = this.modalService.open(SignatureFormComponent, {
      backdrop: 'static',
    })
    modalRef.componentInstance.signature = signature;
    modalRef.result.then((result) => {
      if (result) {
        this.onFind();
      }
    })
  }

  
  public handlePageSizeChange(event: any): void {
    this.pageSize = event.target.value;
    this.page = 1;
  }

}
