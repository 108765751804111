import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Client } from '../../model/client';
import { FormControl, FormGroup } from '@angular/forms';
import { ClientApiService } from '../../service/client-api.service';
import { Pagination } from 'src/app/feature/result/model/pagination';
import { AlertService } from 'src/app/shared/service/alert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientFormComponent } from './client-form/client-form.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html'
})
export class ClientsComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public formGroup: FormGroup;

  public totalRecords: number;
  public pageSizes = [10, 15, 30, 50, 100];
  public pagination: Pagination;

  public clients: Array<Client>;


  constructor(
    private clientApiService: ClientApiService,
    private alertService: AlertService,
    private modalService: NgbModal
  ) { 
    this.pagination = {page: 1, recordPerPage: 10};
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
  
    this.initForm();
    this.onFind();
  }

  private initForm() {
    this.formGroup = new FormGroup({
      parameter: new FormControl(null)
    });
  }

  public onFind() {
    const parameter = this.formGroup.get('parameter').value;
    const sub = this.clientApiService.findParameter(
      this.pagination,
      parameter
    ).subscribe(resp => {
      this.clients = resp.content;
      this.totalRecords = resp.totalRecord;
    });
    this.subscription.add(sub);
  }


  public handlePageChange(event: number): void {
    this.pagination.page = event;
    this.onFind();
  }

  public handlePageSizeChange(event: any): void {
    this.pagination.page = 1;
    this.pagination.recordPerPage =event.target.value;
    this.onFind();
  }

  public onDelete(client: Client) {
    this.alertService.showConfirmation('¿Está seguro que desea inactivar este regitro?').then(resp => {
      if (resp.value) {
        this.clientApiService.delete(client.id).subscribe(() => {
          this.alertService.showSuccessful('El cliente se ha inactivado correctamente', 'Inactivación exitosa')
          this.onFind();
        })
      }
    });
  }

  public onOpenForm(client?: Client) {
    const modalRef = this.modalService.open(ClientFormComponent, {
      backdrop: 'static',
      size: 'lg'
    })
    modalRef.componentInstance.client = client;
    modalRef.result.then((result) => {
      if (result) {
        this.onFind();
      }
    })
  }

}
