<form [formGroup]="formGroup" *ngIf="formGroup" autocomplete="off" novalidate #formRef="ngForm">
  <div class="modal-header">
    <h5 class="card-title" *ngIf="client">
      Editar
    </h5>
    <h5 class="card-title" *ngIf="!client">
      Crear
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="client=null;activeModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card shaded-card">
    <div class="card-body container-fluid">


      <div class="row">
        <div class="form-group col">
          <label>Códigos</label>
          <textarea rows="2" class="form-control" formControlName="code"
            [ngClass]="{ 'is-invalid': controls['code'].errors && formRef.submitted }"></textarea>
          <div *ngIf="formRef.submitted && controls['code'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col">
          <label>Nit</label>
          <input type="text" class="form-control" formControlName="identificationNumber"
            [ngClass]="{ 'is-invalid': controls['identificationNumber'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['identificationNumber'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
        <div class="form-group col">
          <label>Razón social</label>
          <input type="text" class="form-control" formControlName="name"
            [ngClass]="{ 'is-invalid': controls['name'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['name'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col">
          <label>Teléfono</label>
          <input type="text" class="form-control" formControlName="phone"
            [ngClass]="{ 'is-invalid': controls['phone'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['phone'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>

        <div class="form-group col">
          <label>Correo electrónico</label>
          <input type="email" class="form-control" formControlName="email"
            [ngClass]="{ 'is-invalid': controls['email'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['email'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col">
          <label>Contacto</label>
          <input type="text" class="form-control" formControlName="contact"
            [ngClass]="{ 'is-invalid': controls['contact'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['contact'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>

        <div class="form-group col">
          <label>Teléfono de contacto</label>
          <input type="text" class="form-control" formControlName="contactPhone"
            [ngClass]="{ 'is-invalid': controls['contactPhone'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['contactPhone'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col">
          <label>Contraseña</label>
          <input type="password" class="form-control" formControlName="password"
            [ngClass]="{ 'is-invalid': controls['password'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['password'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>

        <div class="form-group col">
          <label>Confirmar contraseña</label>
          <input type="password" class="form-control" formControlName="password2"
            [ngClass]="{ 'is-invalid': controls['password2'].errors && formRef.submitted }">
          <div *ngIf="formRef.submitted && controls['password2'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col">
          <label>Puede descargar resultados?</label>
          <select class="form-control form-select" formControlName="downloadOption"
            [ngClass]="{ 'is-invalid': formRef.submitted && controls['downloadOption'].errors }">
            <option [ngValue]="null">Seleccione</option>
            <option ngValue="si">Si</option>
            <option ngValue="no">No</option>
          </select>
          <div *ngIf="formRef.submitted && controls['downloadOption'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>

        <div class="form-group col">
          <label>Logo</label>
          <select class="form-control form-select" formControlName="logoOption" (change)="onSelectLogoOption()"
            [ngClass]="{ 'is-invalid': formRef.submitted && controls['logoOption'].errors }">
            <option [ngValue]="null">Seleccione</option>
            <option ngValue="sin_logo">Sin logo</option>
            <option ngValue="logo_empresa">Logo empresa</option>
            <option ngValue="logo_sta">Logo Santa Lucía</option>
          </select>
          <div *ngIf="formRef.submitted && controls['logoOption'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
      </div>

      <div class="row" *ngIf="controls['logoOption'].value == 'logo_empresa'">
        <div class="form-group col">
          <div class="custom-file">
            <input type="file" accept="image/*" class="custom-file-input form-control"
              (change)="showHeaderPreview($event)"
              [ngClass]="{ 'is-invalid': formRef.submitted && controls['urlImage'].errors }" />
            <label class="custom-file-label">Seleccione header</label>
            <div *ngIf="formRef.submitted && controls['urlImage'].errors" class="invalid-feedback">
              Este campo es requerido
            </div>
          </div>
          <!-- Image Preview -->
          <div class="mt-2" *ngIf="headerUrl && headerUrl !== ''">
            <img class="imagePreview" [src]="headerUrl" [alt]="">
          </div>
        </div>

        <div class="form-group col">
          <div class="custom-file">
            <input type="file" accept="image/*" class="custom-file-input form-control"
              (change)="showFooterPreview($event)"
              [ngClass]="{ 'is-invalid': formRef.submitted && controls['footer'].errors }" />
            <label class="custom-file-label">Seleccione footer</label>
            <div *ngIf="formRef.submitted && controls['footer'].errors" class="invalid-feedback">
              Este campo es requerido
            </div>
          </div>
          <!-- Image Preview -->
          <div class="mt-2" *ngIf="footerUrl && footerUrl !== ''">
            <img class="imagePreview" [src]="footerUrl" [alt]="">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col">
          <label>Estado</label>
          <select class="form-control form-select" formControlName="active"
            [ngClass]="{ 'is-invalid': formRef.submitted && controls['active'].errors }">
            <option [ngValue]="null">Seleccione</option>
            <option [ngValue]="true">Activo</option>
            <option [ngValue]="false">Inactivo</option>
          </select>
          <div *ngIf="formRef.submitted && controls['active'].errors" class="invalid-feedback">
            Este campo es requerido
          </div>
        </div>
        <div class="form-group col"></div>
      </div>

    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="client=null;activeModal.close()">
      Cancelar
    </button>
    <button type="submit" class="btn btn-primary" (click)="onSave()">
      Guardar
    </button>
  </div>
</form>