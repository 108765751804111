export enum Actions {
    SIGN = 'firmas',
    UPLOAD = 'subir',
    PATIENT = 'gestionar_paciente',
    CLIENT = 'gestionar_cliente',
    RESULT = 'buscar_resultados',
    DOMAIN = 'gestionar_parametros',
    MY_RESULT = 'mis_resultados',
    UPDATE_PATIENT_DATA = 'actualizar_datos_paciente',
    UPDATE_CLIENT_DATA = 'actualizar_datos_cliente',
}
