<main class="center-main">
    <section class="container-fluid">
        <form [formGroup]="formGroup" [formGroup]="formGroup" *ngIf="formGroup" autocomplete="off" novalidate
            #formRef="ngForm">
            <div class="login">
                <div class=" text-center mb-3">
                    <img src="../../../../../assets/images/logo-login.png" class="logo-login" alt="">
                </div>
                <hr />
                <div class="mb-3">
                    <div>
                        <label>Tipo de identificación</label>
                    </div>
                    <div class="form">
                        <select class="form-control form-select" [formControlName]="fields.IDENTIFICATION_TYPE"
                            [ngClass]="{ 'is-invalid': controls[fields.IDENTIFICATION_TYPE].errors && formRef.submitted }">
                            <option [ngValue]="null">Seleccione</option>
                            <option [ngValue]="type.value" *ngFor="let type of indentificationTypes">
                                {{type.title}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="mb-3">
                    <div>
                        <label>Número de identificación</label>
                    </div>
                    <div class="form">
                        <input type="text" class="form-control" [formControlName]="fields.IDENTIFICATION"
                            [ngClass]="{ 'is-invalid': submitted && controls[fields.IDENTIFICATION].errors && formRef.submitted }">
                    </div>
                </div>

                <div class="mb-1">
                    <div>
                        <label>Contraseña</label>
                    </div>
                    <div class="form mb-1">
                        <input type="password" [formControlName]="fields.PASSWORD" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && controls[fields.PASSWORD].errors && formRef.submitted }">
                    </div>
                </div>
                <div class="mb-3">
                    <b><span>NOTA: Su contraseña por defecto es su número de identificación.</span></b>
                </div>       
                <div class="form">
                    <button class="btn btn-primary btn-md btn-block" style="width: 100%;" (click)="onSignIn()">
                        Ingresar
                    </button>
                </div>
            </div>
        </form>
    </section>
</main>