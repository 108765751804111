import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Domain } from '../../../model/domain';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomainApiService } from '../../../service/domain-api.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Option } from 'src/app/shared/model/option';

@Component({
  selector: 'app-domain-form',
  templateUrl: './domain-form.component.html'
})
export class DomainFormComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  @Input()
  public domain: Domain;
  public formGroup: FormGroup;
  public options: Array<Option>

  constructor(private domainApiService: DomainApiService,
    private alertService: AlertService,
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.options = [

    ]
  }

  ngOnDestroy(): void {
    this.formGroup.reset();
    this.domain = null;
    this.subscription.unsubscribe();
  }

  private initForm() {

    this.formGroup = new FormGroup({
      title: new FormControl(this.domain?.title, [Validators.required]),
      name: new FormControl(this.domain?.name, [Validators.required]),
      value: new FormControl(this.domain?.value, [Validators.required]),
      active: new FormControl(this.domain?.active, [Validators.required])
    });
  }


  public get controls() {
    return this.formGroup.controls;
  }

  public onSave() {
    console.log('this.formGroup ', this.formGroup);
    if (this.formGroup.invalid) {
      return;
    }
    const domain = this.formGroup.getRawValue();
    if (this.domain) {
      this.update(domain);
    } else {
      this.save(domain);
    }
  }

  private save(domain: Domain) {
    const sub = this.domainApiService.save(domain).subscribe(() => {
      this.alertService.showSuccessful('Se ha creado el usuario correctamente', 'Creado exito!');
      this.domain = null;
      this.activeModal.close(true);
    });
    this.subscription.add(sub);
  }

  private update(domain: Domain) {
    const sub = this.domainApiService.edit(this.domain.id, domain).subscribe(() => {
      this.alertService.showSuccessful('Se ha actualizado el usuario correctamente', 'Actualizado exito!');
      this.domain = null;
      this.activeModal.close(true);
    });
    this.subscription.add(sub);
  }


}
