import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationRoute } from '../../constant/navigation-route.constant';
import { AuthStorageService } from '../../service/auth-storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

export interface RouteLabel {
  route: string;
  optionName: string;
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public optionName = '';
  public userName = '';

  constructor(
    private router: Router,
    private authStorageService: AuthStorageService,
    private modalService: NgbModal,
    public toastr: ToastrService
  ) { }

  ngOnInit() {
    const user = this.authStorageService.getAuthenticatedUser();
    this.userName = user?.name;
    if(!localStorage.getItem('toast')) {
      this.showInfo();
    }
 
  }

  public onLogout() {
    this.authStorageService.clear();
    this.router.navigate([NavigationRoute.AUTH, NavigationRoute.LOGIN]);
  }



  showInfo() {
    this.toastr.info('Recuerda que tu opinión es muy importante', 'Califícanos', {
      timeOut: 5000,
      positionClass: 'toast-top-center'
    });
    localStorage.setItem('toast', 'true');
  }


}
