import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from '../../../shared/service/base-api.service';
import { Observable } from 'rxjs';
import { Pagination } from '../../result/model/pagination';
import { Page } from 'src/app/shared/model/page';
import { Domain } from '../model/domain';

@Injectable()
export class DomainApiService extends BaseApiService<Domain>  {

  protected override uri = 'v1/domains';

  constructor(httpClient: HttpClient) {
    super(httpClient)
  }

  public findParameter(pagination: Pagination, parameter?: string, active?: boolean, name?: string): Observable<Page<Domain>> {
    const { recordPerPage, page } = pagination;
    const params = `?recordPerPage=${recordPerPage}&page=${page}` + (parameter ? `&parameter=${parameter}` : '')
      + (name ? `&name=${name}` : '') + (active != null ? `&active=${active}` : '');
    console.log('params ', params);
    return this.httpClient.get<Page<Domain>>(
      `${this.apiUlr}${this.uri}/all/show/${params}`
    );
  }

}
