<section class="modales">
  <div class="cabezote-modal">
    <div class="row">
      <div class="col-10 d-flex">
        <div class="mr-2">
          <img src="../../../../../assets/images/close.svg" (click)="activeModal.close('Cross click')" alt="">
        </div>
        <h2 class=" text-white">Cambiar contraseña</h2>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <div class=" ml-auto salvar">
        </div>
      </div>
    </div>
  </div>

  <div class=" p-3" [formGroup]="formGroup">
    <div class="modulos">

      <div class="row">
        <div class="col">
          <div class="mb-4">
            <div class="d-flex justify-content-between ">
              <label class="form-label">Contraseña</label>
            </div>
            <div class="form">
              <input type="text" class="form-control" [formControlName]="fields.PASSWORD"
                [ngClass]="{ 'is-invalid': submitted && controls[fields.PASSWORD].errors }">
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="mb-4">
            <div class="d-flex justify-content-between ">
              <label class="form-label">Confirmar Contraseña</label>
            </div>
            <div class="form">
              <input type="text" class="form-control" [formControlName]="fields.PASSWORD_CONFIRMATION"
                [ngClass]="{ 'is-invalid': submitted && controls[fields.PASSWORD_CONFIRMATION].errors }">
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="mb-3 col">
          <div class="form">
            <button class="boton-big-cancel" (click)="activeModal.close()">
              Cancelar
            </button>
          </div>
        </div>
        <div class="mb-3 col d-flex justify-content-end">
          <div class="form">
            <button class="boton-big" style="width: 100%;" (click)="onChange()">
              Cambiar
            </button>
          </div>
        </div>
      </div>


    </div>
  </div>