import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ClientApiService } from 'src/app/feature/admin/service/client-api.service';
import { UserApiService } from 'src/app/feature/admin/service/user-api.service';
import { UpdatePatientComponent } from './update-patient/update-patient.component';
import { UpdateClientComponent } from './update-client/update-client.component';
@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule
  ],
  declarations: [
    UpdatePatientComponent,
    UpdateClientComponent
  ],
  providers: [
    ClientApiService,
    UserApiService
  ]
})
export class UpdateUserModule { }