<div class="row d-flex align-items-center">
  <div class="col">
    <h3>Pacientes</h3>
  </div>
</div>
<hr />
<form [formGroup]="formGroup" autocomplete="off" novalidate #formRef="ngForm">
  <div class="row mt-2">
    <div class="col">
      <div class="input-group">
        <input type="test" class="form-control" placeholder="Buscar" formControlName="parameter">
        <div class="input-group-append">
          <button class="btn btn-default" type="button" (click)="onFind()">
            <img src="../../../../../assets/images/search.svg" alt="">
          </button>
        </div>
      </div>
    </div>
    <div class="col d-flex justify-content-end">
      <button type="button" class="btn btn-primary" (click)="onOpenForm()">
        Nuevo
      </button>
    </div>
  </div>
</form>
<div class="row mt-2">
  <div class="col" style="overflow-x:auto;">
    <table class="table" aria-describedby="">
      <thead class="thead-light">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Tipo de identificación</th>
          <th scope="col">Identificación</th>
          <th scope="col">Nombres</th>
          <th scope="col">Apellidos</th>
          <th scope="col">Teléfono</th>
          <th scope="col">Correo electrónico</th>
          <th scope="col">Estado</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let user of users | paginate: { itemsPerPage: pagination.recordPerPage, currentPage: pagination.page,  totalItems: totalRecords }; let index=index;">
          <td>{{ 10 * (pagination.page - 1) + (index+1) }}</td>
          <td>{{user.identificationType}}</td>
          <td>{{user.identificationNumber}}</td>
          <td>{{user.name}}</td>
          <td>{{user.lastName}}</td>
          <td>{{user.phone}}</td>
          <td>{{user.email}}</td>
          <td>{{user.active ? 'Activo' : 'Inactivo'}}</td>
          <td>
            <div ngbDropdown class="d-inline-block">
              <button class="btn dropdown-actions" id="dropdownBasic1" ngbDropdownToggle>
                ...
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="onOpenForm(user)">
                  Editar
                </button>
                <button ngbDropdownItem (click)="onDelete(user)">
                  Inactivar
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row">
  <div class="col">
    <hr />
  </div>
</div>
<div class="row" *ngIf="totalRecords > 0">
  <div class="col d-flex justify-content-end align-items-center">
    Registros por pagina:
    <select class="form-control m-2" style="width: auto;" (change)="handlePageSizeChange($event)">
      <option *ngFor="let size of pageSizes" [ngValue]="size">
        {{ size }}
      </option>
    </select>
    <pagination-controls (pageChange)="handlePageChange($event)" previousLabel="Ant." nextLabel="Sig."
      [directionLinks]="true" class="mt-3">
    </pagination-controls>
    <span class="mt-1 ml-3"><strong>Total: </strong>{{totalRecords}}</span>
  </div>
</div>