import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from '../../../../shared/model/user';
import { AuthStorageService } from 'src/app/shared/service/auth-storage.service';
import { PatientExam } from '../../model/patient-exam';
import { ResultApiService } from '../../service/result-api.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PatientApiService } from '../../service/patient-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserDutiesAndRightsComponent } from '../user-duties-and-rights/user-duties-and-rights.component';

@Component({
  selector: 'app-patient-exams',
  templateUrl: './patient-exams.component.html',
  styleUrls: ['./patient-exams.component.css']
})
export class PatientExamsComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public user: User;

  public patientExams: Array<PatientExam>;

  constructor(
    private authStorageService: AuthStorageService,
    private resultApiService: ResultApiService,
    private patientApiService: PatientApiService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      const parametros = params['params'];
      const identificationType = parametros['identificationType'];
      const identificationNumber = parametros['identificationNumber'];
      console.log('identificationType ', identificationType, identificationNumber);
      if(identificationType && identificationNumber) {
        this.patientApiService.findByIdentification(identificationType, identificationNumber).subscribe(resp => {
          this.user = resp
          this.checkIfPerson()
          this.find();
        });
      }else {
        this.user = this.authStorageService.getAuthenticatedUser();
        this.checkIfPerson()
        this.find();
      }

    });
  }

  private checkIfPerson() {
    if(this.user?.type == 'person' && !this.authStorageService.isDutiesAndRights()) {
      const modalRef = this.modalService.open(UserDutiesAndRightsComponent, {
        size: 'lg',
        backdrop: 'static'
      });
      modalRef.result.then(() => {})
    }
  }

  private find() {
    const sub = this.resultApiService.findByPatient(
      this.user.identificationType, this.user.identificationNumber
    ).subscribe(resp => {
      this.patientExams = resp;
    });
    this.subscription.add(sub);
  }

  public onDownloadResult(requestCode: string, url: string) {
    console.log('this.user ', this.user);
    if(  this.user.type == 'person'){
      const sub = this.patientApiService.validateIfPaid(requestCode).subscribe(() => {
        this.downloadResult(requestCode, url)
      });
      this.subscription.add(sub);
    }else {
      this.downloadResult(requestCode, url)
    }

  }

  private downloadResult(requestCode: string, url: string) {
    const sub = this.resultApiService.download(
      requestCode,
      url
    ).subscribe(resp => {
      console.log('resp ', resp);
      const nombreArchivo = requestCode + '.pdf';
      //const blob = new Blob([resp], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(resp);
      const donwloadLink = document.createElement('a');
      donwloadLink.href = url;
      donwloadLink.setAttribute('download', nombreArchivo);
      document.body.appendChild(donwloadLink);
      donwloadLink.click();
    });
    this.subscription.add(sub);
  }
}
