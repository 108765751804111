<section class="modales">
  <div class="modal-header">
    <h5 class="card-title">
     Cambiar contraseña
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class=" p-3" [formGroup]="formGroup">
    <div class="modulos">

      <div class="row">
        <div class="col">
          <div class="mb-4">
            <div class="d-flex justify-content-between ">
              <label class="form-label">Contraseña actual</label>
            </div>
            <div class="form">
              <input type="text" class="form-control" [formControlName]="fields.PASSWORD"
                [ngClass]="{ 'is-invalid': submitted && controls[fields.PASSWORD].errors }">
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="mb-4">
            <div class="d-flex justify-content-between ">
              <label class="form-label">Nueva Contraseña</label>
            </div>
            <div class="form">
              <input type="text" class="form-control" [formControlName]="fields.NEW_PASSWORD"
                [ngClass]="{ 'is-invalid': submitted && controls[fields.NEW_PASSWORD].errors }">
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="mb-4">
            <div class="d-flex justify-content-between ">
              <label class="form-label">Confirmar Contraseña</label>
            </div>
            <div class="form">
              <input type="text" class="form-control" [formControlName]="fields.PASSWORD_CONFIRMATION"
                [ngClass]="{ 'is-invalid': submitted && controls[fields.PASSWORD_CONFIRMATION].errors }">
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="mb-3 col">
          <div class="form">
            <button class="btn btn-secondary" (click)="activeModal.close()">
             Cancelar
            </button>
          </div>
        </div>
        <div class="mb-3 col d-flex justify-content-end">
          <div class="form">
            <button class="btn btn-primary" style="width: 100%;" (click)="onChange()">
              Cambiar
            </button>
          </div>
        </div>
      </div>


    </div>
  </div>