import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserApiService } from 'src/app/feature/admin/service/user-api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/shared/model/user';
import { Domain } from 'src/app/shared/model/domain';
import { AlertService } from 'src/app/shared/service/alert.service';
import { DomainFinderApiService } from 'src/app/shared/service/domain-finder-api.service';
import { DomainNames } from 'src/app/shared/enum/domain-names.enum';

@Component({
  selector: 'app-update-patient',
  templateUrl: './update-patient.component.html'
})
export class UpdatePatientComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  @Input()
  public user: User;
  public formGroup: FormGroup;
  public indentificationTypes: Array<Domain>

  constructor(private userApiService: UserApiService,
    private alertService: AlertService,
    public activeModal: NgbActiveModal,
    private domainApiService: DomainFinderApiService
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.findIdentificationTypes();
  }

  ngOnDestroy(): void {
    this.formGroup.reset();
    this.user = null;
    this.subscription.unsubscribe();
  }

  private initForm() {
    this.formGroup = new FormGroup({
      identificationType: new FormControl(this.user?.identificationType, [Validators.required]),
      identificationNumber: new FormControl(this.user?.identificationNumber, [Validators.required]),
      name: new FormControl(this.user?.name, [Validators.required]),
      lastName: new FormControl(this.user?.lastName, [Validators.required]),
      phone: new FormControl(this.user?.phone, [Validators.required]),
      email: new FormControl(this.user?.email, [Validators.required, Validators.email]),
    });
  }

  private findIdentificationTypes() {
    this.domainApiService.findActivesByName(DomainNames.IDENTIFICATION_TYPE).subscribe(resp => {
      this.indentificationTypes = resp;
    })
  }


  public get controls() {
    return this.formGroup.controls;
  }

  public onSave() {
    if (this.formGroup.invalid) {
      return;
    }
    const user = this.formGroup.getRawValue();
    this.update(user);

  }
  private update(user: User) {
 
    const sub = this.userApiService.edit(this.user.id, user).subscribe(() => {
      this.alertService.showSuccessful('Se ha actualizado su información correctamente', 'Actualizado exito!');
      this.setStorage(user);
      this.activeModal.close(this.user);
    });
    this.subscription.add(sub);
  }

  private setStorage(user: User) {
    this.user.identificationNumber = user.identificationNumber;
    this.user.identificationType = user.identificationType;
    this.user.name = user.name;
    this.user.lastName = user.lastName;
    this.user.phone = user.phone;
    this.user.email = user.email;
  }
}
